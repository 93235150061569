import api from './api';

export const UsersBankAccounts = () => {
    return api.get('/v1/crm/UsersBankAccounts');
};

export const createUsersBankAccounts = (data) => {
    return api.post('/v1/crm/UsersBankAccounts', data);
};

export const updateUsersBankAccounts= (id, data) => {
    return api.put(`/v1/crm/UsersBankAccounts/${id}`, data);
};

export const deleteUsersBankAccounts = (id) => {
    return api.delete(`/v1/crm/UsersBankAccounts/${id}`);
};
