import api from './api';

export const UsersContracts = () => {
    return api.get('/v1/crm/UsersContracts');
};

export const createUsersContracts = (data) => {
    return api.post('/v1/crm/UsersContracts', data);
};

export const updateUsersContracts = (id, data) => {
    return api.put(`/v1/crm/UsersContracts/${id}`, data);
};

export const deleteUsersContracts = (id) => {
    return api.delete(`/v1/crm/UsersContracts/${id}`);
};
