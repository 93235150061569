import api from './api';

export const UsersWarning = () => {
    return api.get('/v1/crm/UsersWarning');
};

export const createUsersWarning = (data) => {
    return api.post('/v1/crm/UsersWarning', data);
};

export const updateUsersWarning = (id, data) => {
    return api.put(`/v1/crm/UsersWarning/${id}`, data);
};

export const deleteUsersWarning = (id) => {
    return api.delete(`/v1/crm/UsersWarning/${id}`);
};
