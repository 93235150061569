import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const PersonalInfoModal = ({ isOpen, toggle, formValues, handleFormChange, handleSaveUserInfo }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Personal Info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              name="gender"
              id="gender"
              value={formValues.gender}
              onChange={handleFormChange}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="birth_date">Birth Date</Label>
            <Input
              type="date"
              name="birth_date"
              id="birth_date"
              value={formValues.birth_date}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="marital_status">Marital Status</Label>
            <Input
              type="select"
              name="marital_status"
              id="marital_status"
              value={formValues.marital_status}
              onChange={handleFormChange}
            >
              <option value="">Select Marital Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Not Mentioned">Not Mentioned</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="nationality">Nationality</Label>
            <Input
              type="text"
              name="nationality"
              id="nationality"
              value={formValues.nationality}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="national_id">National ID</Label>
            <Input
              type="text"
              name="national_id"
              id="national_id"
              value={formValues.national_id}
              onChange={handleFormChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveUserInfo}>
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PersonalInfoModal;
