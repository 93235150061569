import React, { useState, Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FinanceBank.css';  // Import the CSS file

const FinanceBank = () => {
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState('');
  const [rate, setRate] = useState('');
  const [conversionRates, setConversionRates] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'INR', label: 'INR' },
    { value: 'PKR', label: 'PKR' },
    { value: 'AED', label: 'AED' },
    { value: 'GBP', label: 'GBP' },
    // Add more currencies as needed
  ];

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleCurrencyChange = (selectedOption) => setCurrency(selectedOption);
  const handleRateChange = (e) => setRate(e.target.value);

  const handleSave = () => {
    const newRate = { currency: currency.value, rate };
    setConversionRates([...conversionRates, newRate]);
    setShowModal(false);
    setCurrency('');
    setRate('');
  };

  return (
    <Fragment>
      <Container fluid={true} style={{ height: '30px' }}>
        <div className="page-title" style={{ display: 'none' }}>
          <Row>
            <Col className="col-6">
              <h3></h3>
            </Col>
            <Col className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col md="2" className="mb-2 mb-md-0 text-left">
                    <h5>Banks And Mediums</h5>
                  </Col>
                  <Col md="10" className="d-flex justify-content-end">
                    <FormGroup className="mr-2">
                      <Label for="startDate">From</Label>
                      <DatePicker
                        id="startDate"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MM/dd/yyyy"
                        className="form-control form-control-sm"
                      />
                    </FormGroup>
                    <FormGroup className="mr-2">
                      <Label for="endDate">To</Label>
                      <DatePicker
                        id="endDate"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                        className="form-control form-control-sm"
                      />
                    </FormGroup>
                    <Button color="primary" onClick={handleShowModal} style={{ marginTop: '27px' }}>
                    Add Conversion Rate
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      <th>Sl. No</th>
                      <th>Bank Name</th>
                      <th>Currency</th>
                      <th>Previous Balance</th>
                      <th>Closing Balance</th>
                      <th>Conversion Rate</th>
                      <th>Amount in USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Populate table rows here */}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Add Conversion Rate</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="currency">Currency</Label>
              <Select
                id="currency"
                options={currencyOptions}
                value={currency}
                onChange={handleCurrencyChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="rate">Conversion Rate</Label>
              <Input
                id="rate"
                type="number"
                step="0.01"
                value={rate}
                onChange={handleRateChange}
                placeholder="Enter rate"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default FinanceBank;
