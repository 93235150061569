import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField
} from '@mui/material';
import { mt5SwapInfo, mt4SwapInfo } from 'Services';
import { swapGroup } from 'Services'; // Adjust this import to the correct path

function SwapReport() {
  const [mt4Data, setMt4Data] = useState([]);
  const [mt5Data, setMt5Data] = useState([]);
  const [latestMt4Date, setLatestMt4Date] = useState('');
  const [latestMt5Date, setLatestMt5Date] = useState('');
  const [searchTermMt4, setSearchTermMt4] = useState('');
  const [searchTermMt5, setSearchTermMt5] = useState('');
  const [pageMt4, setPageMt4] = useState(0);
  const [pageMt5, setPageMt5] = useState(0);
  const [rowsPerPageMt4, setRowsPerPageMt4] = useState(10);
  const [rowsPerPageMt5, setRowsPerPageMt5] = useState(10);
  const [mt4Count, setMt4Count] = useState(0); // State for MT4 group count
  const [mt5Count, setMt5Count] = useState(0); // State for MT5 group count

  useEffect(() => {
    mt5SwapInfo.mt5swapReport()
      .then((res) => {

        setMt5Data(res);
        if (res && res.length > 0) {
          const latestDate = res.reduce((latest, row) =>
            new Date(latest.StoreDate) > new Date(row.StoreDate) ? latest : row
          );
       
          setLatestMt5Date(formatDate(latestDate.StoreDate));
        }
      })
      .catch((err) => console.log("Error fetching MT5 data: ", err));

    mt4SwapInfo.mt4swapReport()
      .then((res) => {
     
        if (Array.isArray(res)) {
          setMt4Data(res);
          if (res.length > 0) {
            const latestDate = res.reduce((latest, row) =>
              new Date(latest.today_date) > new Date(row.today_date) ? latest : row
            );
         
            setLatestMt4Date(formatDate(latestDate.today_date));
          }
        } else {
          setMt4Data([]);
        }
      })
      .catch((err) => {
        console.log("Error fetching MT4 data: ", err);
        setMt4Data([]);
      });
  }, []);

  useEffect(() => {
    swapGroup.swapGroup()
      .then((res) => {
        const mt4Groups = res.filter(group => group.Platform === 'MT4').length;
        const mt5Groups = res.filter(group => group.Platform === 'MT5').length;
        setMt4Count(mt4Groups);
        setMt5Count(mt5Groups);
      })
      .catch((error) => {
        console.error('Error fetching swap group data:', error);
      });
  }, []);


  const handleSearchMt4 = (event) => {
    setSearchTermMt4(event.target.value);
    setPageMt4(0); // Reset to the first page when a search is performed
  };

  const handleSearchMt5 = (event) => {
    setSearchTermMt5(event.target.value);
    setPageMt5(0); // Reset to the first page when a search is performed
  };

  const handleChangePageMt4 = (event, newPage) => {
    setPageMt4(newPage);
  };

  const handleChangePageMt5 = (event, newPage) => {
    setPageMt5(newPage);
  };

  const handleChangeRowsPerPageMt4 = (event) => {
    setRowsPerPageMt4(parseInt(event.target.value, 10));
    setPageMt4(0);
  };

  const handleChangeRowsPerPageMt5 = (event) => {
    setRowsPerPageMt5(parseInt(event.target.value, 10));
    setPageMt5(0);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().split('T')[0];
  };

  const isYesterday = (dateString) => {
    const date = new Date(dateString).toISOString().split('T')[0];
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toISOString().split('T')[0];
    return date === yesterdayString;
  };

  const filteredMt4Data = mt4Data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTermMt4.toLowerCase())
    )
  ).filter(row => isYesterday(row.today_date));

  const filteredMt5Data = mt5Data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTermMt5.toLowerCase())
    )
  ).filter(row => isYesterday(row.StoreDate));


  const mt4DoneData = filteredMt4Data.filter(row => row.swap_charged === 1);
  const mt4DoneCount = mt4DoneData.length;
  const mt4DoneSum = Math.abs(mt4DoneData.reduce((acc, row) => acc + parseFloat(row.swap_charge || 1), 0));
  
  const mt5DoneData = filteredMt5Data.filter(row => row.Applied === 0);
  const mt5DoneCount = mt5DoneData.length;
  const mt5DoneSumPrevious = mt5DoneData.reduce((acc, row) => acc + parseFloat(row.Storage || 0), 0);
  const mt5DoneSumCurrent = mt5DoneData.reduce((acc, row) => acc + parseFloat(row.SwapCalculation || 0), 0);
  const mt5Difference = mt5DoneSumPrevious - mt5DoneSumCurrent;

  const CustomTablePagination = (props) => (
    <TablePagination
      {...props}
      sx={{
        '& .MuiTablePagination-selectLabel': {
          marginBottom: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          marginBottom: 0,
        },
      }}
    />
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {/* Card for MT4 */}
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: '#AAC1FC' }}>
            <CardContent>
              <Typography variant="h6" component="div">
                Swap Plugin - MT4
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="subtitle1" component="div">
                  Detailed Reports For MT4
                </Typography>
                <Typography variant="h4" component="div">
                  ${mt4DoneSum.toFixed(2)}
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Total Swap Charged
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Last swap charged Date : {latestMt4Date}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                  <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                    Today Swap Charged Order: {mt4DoneCount}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                  <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                    Total Group: {mt4Count}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for MT5 */}
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: '#AADAFC' }}>
            <CardContent>
              <Typography variant="h6" component="div">
                Swap Plugin - MT5
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="subtitle1" component="div">
                  Detailed Reports For MT5
                </Typography>
                <Typography variant="h4" component="div">
                  ${mt5Difference.toFixed(2)}
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Total Swap Charged
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Last swap charged Date : {latestMt5Date}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                  <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                    Today Swap Charged Order: {mt5DoneCount}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                  <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                    Total Group: {mt5Count}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Heading and search bar for MT4 Table */}
      <Typography variant="h6" component="div" sx={{ marginTop: 4 }}>
        MT4 Swap Charged Position
      </Typography>
      <TextField
        label="Search MT4"
        variant="outlined"
        fullWidth
        sx={{ marginY: 2 }}
        onChange={handleSearchMt4}
      />

      {/* Table for MT4 */}
      <TableContainer component={Paper}>
        <Table aria-label="MT4 orders table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Platform</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Login</TableCell>
              <TableCell>GroupName</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>TimeCreate</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Swap Charge</TableCell>
              <TableCell>Total Days</TableCell>
              <TableCell>Day Count</TableCell>
              <TableCell>StoreDate</TableCell>
              <TableCell>Swap Current</TableCell>
              <TableCell>Applied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMt4Data
              .slice(pageMt4 * rowsPerPageMt4, pageMt4 * rowsPerPageMt4 + rowsPerPageMt4)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{pageMt4 * rowsPerPageMt4 + index + 1}</TableCell>
                  <TableCell>MT4</TableCell>
                  <TableCell>{row.ticket}</TableCell>
                  <TableCell>{row.login}</TableCell>
                  <TableCell>{row.group}</TableCell>
                  <TableCell>{row.symbol}</TableCell>
                  <TableCell>{formatDate(row.open_time)}</TableCell>
                  <TableCell>{row.cmd === 1 ? 'Sell' : 'Buy'}</TableCell>
                  <TableCell>{row.volume}</TableCell>
                  <TableCell>{row.swap_charge}</TableCell>
                  <TableCell>{row.swap_free_days}</TableCell>
                  <TableCell>{row.today_count}</TableCell>
                  <TableCell>{formatDate(row.today_date)}</TableCell>
                  <TableCell>{row.swap_charged}</TableCell>
                  <TableCell>{row.swap_charged === 1 ? 'Done' : 'Cancelled'}</TableCell>
                </TableRow>
              ))}
            {filteredMt4Data.length === 0 && (
              <TableRow>
                <TableCell colSpan={15} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
          component="div"
          count={filteredMt4Data.length}
          rowsPerPage={rowsPerPageMt4}
          page={pageMt4}
          onPageChange={handleChangePageMt4}
          onRowsPerPageChange={handleChangeRowsPerPageMt4}
        />
      </TableContainer>

      {/* Heading and search bar for MT5 Table */}
      <Typography variant="h6" component="div" sx={{ marginTop: 4 }}>
        MT5 Swap Charged Position
      </Typography>
      <TextField
        label="Search MT5"
        variant="outlined"
        fullWidth
        sx={{ marginY: 2 }}
        onChange={handleSearchMt5}
      />

      {/* Table for MT5 */}
      <TableContainer component={Paper}>
        <Table aria-label="MT5 orders table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Platform</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Login</TableCell>
              <TableCell>GroupName</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>TimeCreate</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Swap (previous)</TableCell>
              <TableCell>Total Days</TableCell>
              <TableCell>Day Count</TableCell>
              <TableCell>StoreDate</TableCell>
              <TableCell>Swap (current)</TableCell>
              <TableCell>Applied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMt5Data
              .slice(pageMt5 * rowsPerPageMt5, pageMt5 * rowsPerPageMt5 + rowsPerPageMt5)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{pageMt5 * rowsPerPageMt5 + index + 1}</TableCell>
                  <TableCell>MT5</TableCell>
                  <TableCell>{row.Position}</TableCell>
                  <TableCell>{row.Login}</TableCell>
                  <TableCell>{row.GroupName}</TableCell>
                  <TableCell>{row.Symbol}</TableCell>
                  <TableCell>{formatDate(row.TimeCreate)}</TableCell>
                  <TableCell>{row.Action === 1 ? 'Sell' : 'Buy'}</TableCell>
                  <TableCell>{row.Volume}</TableCell>
                  <TableCell>{row.Storage}</TableCell>
                  <TableCell>{row.daycountTotal}</TableCell>
                  <TableCell>{row.daycountActual}</TableCell>
                  <TableCell>{formatDate(row.StoreDate)}</TableCell>
                  <TableCell>{row.SwapCalculation}</TableCell>
                  <TableCell>{row.Applied === 0 ? 'Done' : 'Cancelled'}</TableCell>
                </TableRow>
              ))}
            {filteredMt5Data.length === 0 && (
              <TableRow>
                <TableCell colSpan={15} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50,100, 250, 500, 1000]}
          component="div"
          count={filteredMt5Data.length}
          rowsPerPage={rowsPerPageMt5}
          page={pageMt5}
          onPageChange={handleChangePageMt5}
          onRowsPerPageChange={handleChangeRowsPerPageMt5}
        />
      </TableContainer>
    </Box>
  );
}

export default SwapReport;
