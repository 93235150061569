import api from './api';

export const UsersTicketAllowances = () => {
    return api.get('/v1/crm/UsersTicketAllowances');
};

export const createUsersTicketAllowances = (data) => {
    return api.post('/v1/crm/UsersTicketAllowances', data);
};

export const updateUsersTicketAllowances = (id, data) => {
    return api.put(`/v1/crm/UsersTicketAllowances/${id}`, data);
};

export const deleteUsersTicketAllowances = (id) => {
    return api.delete(`/v1/crm/UsersTicketAllowances/${id}`);
};
