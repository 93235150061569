import api from './api';

export const UsersFamilyDetail = () => {
    return api.get('/v1/crm/UsersFamilyDetail');
};

export const createUsersFamilyDetail = (data) => {
    return api.post('/v1/crm/UsersFamilyDetail', data);
};

export const updateUsersFamilyDetail = (id, data) => {
    return api.put(`/v1/crm/UsersFamilyDetail/${id}`, data);
};

export const deleteUsersFamilyDetail = (id) => {
    return api.delete(`/v1/crm/UsersFamilyDetail/${id}`);
};
