import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};

export const getLeads = (params) => {
  console.log("Get Leads Params ",params);
  const { page, limit, ...searchValue } = params;
  const queryString = `page=${page}&limit=${limit}`;
  return api.get(`/v1/crm/customers/leads?${queryString}`, searchValue);
};

export const getSubLeads = () => {
  return api.get(`/v1/crm/customers/getSubLeads`);
}
/*export const searchCustomers = (params) => {
  return api.get('/v1/crm/customers/search-customers?' + queryString(params));
};*/

export const searchCustomers = (params) => {
  const { page, limit, ...bodyParams } = params;
  const queryString = `page=${page}&limit=${limit}`;
  return api.post(`/v1/crm/customers/search-customers?${queryString}` , bodyParams);
};

export const postBulkLeads = (params) => {
  return api.post('/v1/crm/customers/leadbulk', params, {});
};
export const deleteBulkLeads = (data) => {
  return api.delete('/v1/crm/customers/leads', { data }, {});
};
export const postLead = (params, token) => {
  return api.post('/v1/crm/customers/lead', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// export const delLead = (params)=> {
//   return api.delete('/v1/crm/users/'+ params)
// }
export const updateLead = (id, params) => {
  return api.patch('/v1/crm/customers/lead/' + id, params);
};
