import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { addImage } from 'Services/usersPersonalDetail.service';

const UserInfoModal = ({ isOpen, toggle, formValues, handleFormChange, handleSaveUserInfo }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Edit User Info
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={formValues.name}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="employment_number">Employment Number</Label>
            <Input
              type="text"
              name="employment_number"
              id="employment_number"
              value={formValues.employment_number}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userid">User Support ID</Label>
            <Input
              type="text"
              name="userid"
              id="userid"
              value={formValues.userid}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="photo">Photo</Label>
            <Input
              type="file"
              name="photo"
              id="photo"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                const file = e.target.files[0];
                handleFormChange({ target: { name: 'photo', value: file } });
                addImage(e.target.value);
              }}
            />
            {formValues.photo && (
              <div style={{ marginTop: '10px' }}>
                <img src={formValues.photo} alt="Selected" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
              </div>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveUserInfo}>
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserInfoModal;
