import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FInput, FSelect } from "CommonElements/Form";

import CommonModal from "../../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal";
import { roleService, userService } from "Services";
import { toast } from "react-toastify";

import { ModalFooter } from "reactstrap";
import { Close, SaveChanges } from "../../../../Constant";

const languages = [
  { label: 'ENGLISH',name: 'ENGLISH', value: 'ENGLISH' },
  { label: 'ARABIC',name: 'ARABIC', value: 'ARABIC' },
  { label: 'BOTH',name: 'BOTH', value: 'BOTH' }
];
const UserFormModal = (
  { modal, NewMessage, toggle, setModal, editData,loadData },
  props
) => {
  const { data = {} } = props;
  const [roleList, setSetRoleList] = useState([]);
  const [isEditUser, setIsEditUser] = useState(false);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    reset,
  } = useForm({defaultValues: {
    language: data.language || languages[0].value
  }});
  useEffect(() => {
    if (!data.language) {
      setValue('language', languages[0].value);
    }
  }, [data.language, setValue]);
  useEffect(() => {
    if (!modal) {
      clearErrors();
      reset({
        firstName: "",
        lastName: "",
        roleId: "",
        email: "",
        phone: "",
        password: "",
        language: "",
      });
      setIsEditUser(false);
    }
    if (modal && editData != null) {
      const formFields = getValues();
      Object.keys(formFields).forEach((key) => {
        if (editData[key]) {
          setValue(key, editData[key]);
          console.log("data is ", editData[key],formFields,editData);
        }
        setIsEditUser(true);
      });
      
    }
  }, [modal]);
  useEffect(() => {
    if (!roleList.length > 0) {
      roleService
        .getRoles({limit: 20})
        .then((res) => {
          let roleList = [];
          if (res.data.length > 0) {
            res.data.map((item) => {
              return roleList.push({ name: item.title, value: item.id });
            });
            setSetRoleList(roleList);
          } else {
            toast.error("No Role Found ");
          }
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });

          toast.error("No Role Found ");
        });
    }
  }, [roleList]);
  const onSubmit = (data) => {
    if (data !== "") {
      setSubmitState({ loading: true });

      if (editData == null) {
        const payload = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          roleId: data.roleId.toString(),
          phone: data.phone,
          password: data.password,
          language: data.language,
        };
        userService
          .addUser(payload)
          .then((res) => {
            setSubmitState({
              loading: false,
            });
            loadData(1);
            toast.success("User Create Successfully");
            setModal(false);
          })
          .catch((err) => {
            setSubmitState({
              loading: false,
            });
            toast.error("User Not Created ");
          });
      } else {
        const payload = {
          firstName: data.firstName,
          lastName: data.lastName,
          roleId: data.roleId.toString(),
          language: data.language,
        };
        userService
          .updateUser(payload, editData.id)
          .then((res) => {
            setSubmitState({
              loading: false,
            });
            loadData(1);
            toast.success("User Update Successfully");
            setModal(false);
          })
          .catch((err) => {
            setSubmitState({
              loading: false,
            });
            toast.error("User Not Updated ");
          });
      }
      // alert('You submitted the form and stuff!');
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md="6">
              <FInput
                type="text"
                errors={errors}
                label="First Name"
                name="firstName"
                register={register}
                placeholder="Enter First Name"
                value={data.firstName}
                validation={{ required: true }}
              />
            </Col>
            <Col md="6">
              <FInput
                type="text"
                errors={errors}
                label="Last Name"
                name="lastName"
                register={register}
                placeholder="Enter Last Name"
                value={data.lastName}
                validation={{ required: true }}
              />
            </Col>
            <Row style={{ marginBottom: 10 }}></Row>
            <Col md={isEditUser ? "12" : "6"}>
              <FSelect
                control={control}
                errors={errors}
                label="Select Role"
                name="roleId"
                register={register}
                placeholder="Select Role"
                options={roleList}
                validation={{ required: true }}
                value={data.roleId}
                setValue={setValue}
              />
            </Col>
            <Col md={isEditUser ? "12" : "6"}>
              <FSelect
                control={control}
                errors={errors}
                label="Select Language"
                name="language"
                register={register}
                placeholder="Select Language"
                options={languages}
                validation={{ required: true }}
                value={data.language}
                setValue={setValue}
              />
            </Col>
            {!isEditUser ? (
              <Col md="6">
                <FInput
                  type="text"
                  errors={errors}
                  label="Email"
                  name="email"
                  register={register}
                  placeholder="Enter Your Email"
                  value={data.lastName}
                  validation={{ required: true }}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {!isEditUser ? (
            <Row style={{ marginBottom: 10 }}>
              <Col md="6">
                <FInput
                  type="text"
                  errors={errors}
                  label="Phone"
                  name="phone"
                  register={register}
                  placeholder="Enter Your Phone Number"
                  value={data.phone}
                  validation={{ required: false }}
                />
              </Col>
              <Col md="6">
                <FInput
                  type="passowrd"
                  errors={errors}
                  label="Password"
                  name="password"
                  register={register}
                  placeholder="Enter Your Password"
                  value={data.password}
                  validation={{ required: true }}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          <ModalFooter>
            <Btn
              attrBtn={{
                color: "secondary",
                onClick: () => setModal(false),
                type: "button",
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: "primary" }}
              // onSubmit={() => handleMyFunction()}
            >
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default UserFormModal;
