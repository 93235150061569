import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const ContactInfoModal = ({ isOpen, toggle, formValues, handleFormChange, handleSaveUserInfo }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Contact Info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={formValues.email}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mobile_number">Mobile Number</Label>
            <Input
              type="text"
              name="mobile_number"
              id="mobile_number"
              value={formValues.mobile_number}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="phone_no_ext">Phone No. And Ext</Label>
            <Input
              type="text"
              name="phone_no_ext"
              id="phone_no_ext"
              value={formValues.phone_no_ext}
              onChange={handleFormChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveUserInfo}>
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContactInfoModal;
