import React, { useState, useEffect, Fragment } from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap';
import Calendar from 'react-beautiful-calendar';
import './AdminDashboard.css';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';

const HrmsAdminDashboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [formValues, setFormValues] = useState({
    eventTitle: '',
    eventPriority: 'normal',
    eventDate: new Date().toISOString().substr(0, 10),
  });
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const allUsers = await UsersPersonalDetail(); // Fetch all user details
        const today = new Date();
        const threeWeeksFromNow = new Date();
        threeWeeksFromNow.setDate(today.getDate() + 21);

        const upcoming = allUsers
          .map(user => {
            const birthDateThisYear = new Date(user.birth_date);
            birthDateThisYear.setFullYear(today.getFullYear());

            const timeDiff = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24)) - 1;

            return { ...user, timeDiff, birthDateThisYear };
          })
          .filter(user => user.timeDiff >= 0 && user.birthDateThisYear <= threeWeeksFromNow)
          .sort((a, b) => a.timeDiff - b.timeDiff);

        setUpcomingBirthdays(upcoming);
      } catch (error) {
        console.error('Error fetching upcoming birthdays:', error);
      }
    };

    fetchBirthdays();
  }, []);

  const formatTimeLeft = (daysLeft) => {
    const weeks = Math.floor(daysLeft / 7);
    const days = daysLeft % 7;
    if (weeks > 0 && days > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''} left`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} left`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    if (formValues.eventTitle) {
      setEvents([
        ...events,
        {
          date: new Date(formValues.eventDate),
          title: formValues.eventTitle,
          priority: formValues.eventPriority,
        },
      ]);
      toggle();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getEventColor = (priority) => {
    switch (priority) {
      case 'low':
        return 'green';
      case 'high':
        return 'red';
      default:
        return 'blue';
    }
  };

  return (
    <Fragment>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Row className="card-container">
        <Col sm="4" className="card-column">
          <Card className="card-common who-is-off">
            <CardHeader>
              <h5>Who’s Off / Working Remotely</h5>
            </CardHeader>
            <CardBody>
              <p>No one’s off!</p>
            </CardBody>
          </Card>
        </Col>

        <Col sm="4" className="card-column">
          <Card className="card-common time-off-balance">
            <CardHeader>
              <h5>Your Time Off Balance</h5>
            </CardHeader>
            <CardBody>
              <p>No available balances</p>
            </CardBody>
          </Card>
        </Col>

        <Col sm="4" className="card-column">
          <Card className="card-common announcements">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5>Announcements</h5>
              <div>
                <Button color="link" className="p-0 anno">+ Add</Button>
                <Button color="link" className="p-0 anno">View All</Button>
              </div>
            </CardHeader>
            <CardBody>
              <p>No Announcements</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="birthdays-and-calendar">
        <Col sm="6">
          <Card className="card-common upcoming-birthdays">
            <CardHeader>
              <h5>Upcoming Birthdays 🎂</h5>
            </CardHeader>
            <CardBody>
              <ul>
                {upcomingBirthdays.length > 0 ? (
                  upcomingBirthdays.map((user, index) => (
                    <li key={index}>
                      <span className="initial">{user.name.charAt(0)}</span>
                      <div className="birthday-info">
                        <p className="name">{user.name}</p>
                        <p className="date">{new Date(user.birth_date).toLocaleDateString()}</p>
                      </div>
                      <p className="time-left">{formatTimeLeft(user.timeDiff)}</p>
                    </li>
                  ))
                ) : (
                  <p>No upcoming birthdays in the next 3 weeks</p>
                )}
              </ul>
            </CardBody>
          </Card>
        </Col>

        <Col sm="6">
          <Card className="card-common calendar">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5>Events</h5>
              <Button color="primary" onClick={toggle}>Add Event</Button>
            </CardHeader>
            <CardBody>
              <Calendar
                variant="default"
                font="Arial"
                onDateChange={handleDateChange}
                value={selectedDate}
                events={events.map((event) => ({
                  date: event.date,
                  title: event.title,
                  color: getEventColor(event.priority),
                }))}
                style={{ width: '100%' }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Event</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="eventTitle">Event Title</Label>
              <Input
                type="text"
                name="eventTitle"
                id="eventTitle"
                value={formValues.eventTitle}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="eventDate">Date</Label>
              <Input
                type="date"
                name="eventDate"
                id="eventDate"
                value={formValues.eventDate}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="eventPriority">Priority</Label>
              <Input
                type="select"
                name="eventPriority"
                id="eventPriority"
                value={formValues.eventPriority}
                onChange={handleChange}
              >
                <option value="low">Low</option>
                <option value="normal">Normal</option>
                <option value="high">High</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Submit</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default HrmsAdminDashboard;
