// src/components/FinanceDashboard.js
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ArcElement
);

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f4f4f4;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const SummarySection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const ChartsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ChartRow = styled.div`
  display: flex;
  gap: 20px;
`;

const Card = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const SmallCard = styled(Card)`
  flex: 0 1 400px; /* Adjust width as needed */
`;

const CardTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
`;

const Value = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #555;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const FinanceDashboard = () => {
  const incomeData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [
      {
        label: 'Income',
        data: [1200, 1900, 3000, 5000, 2300, 3400, 2400, 2900, 3200, 2700, 2900, 3100],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderWidth: 1,
      },
      {
        label: 'Expenses',
        data: [1000, 1400, 2000, 4000, 1500, 2000, 1600, 2200, 2700, 2400, 2600, 2900],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderWidth: 1,
      },
    ],
  };

  const netProfitData = {
    labels: ['Net Profit Margin %'],
    datasets: [
      {
        data: [25, 75],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(201, 203, 207, 0.6)'],
        hoverBackgroundColor: ['rgba(75, 192, 192, 0.8)', 'rgba(201, 203, 207, 0.8)'],
      },
    ],
  };

  const tableData = [
    { month: 'January', income: 4719, expenses: 3270, profit: 629 },
    { month: 'February', income: 4900, expenses: 3400, profit: 1500 },
    // Add more rows as needed
  ];

  return (
    <DashboardContainer>
      <Header>Finance Dashboard</Header>
      <SummarySection>
        <Card>
          <CardTitle>Total Income</CardTitle>
          <Value>$4,719.00</Value>
        </Card>
        <Card>
          <CardTitle>Total Expenses</CardTitle>
          <Value>$3,270.00</Value>
        </Card>
        <Card>
          <CardTitle>Accounts Receivable</CardTitle>
          <Value>$609.00</Value>
        </Card>
        <Card>
          <CardTitle>Accounts Payable</CardTitle>
          <Value>$538.00</Value>
        </Card>
        <Card>
          <CardTitle>Net Profit</CardTitle>
          <Value>$629.00</Value>
        </Card>
        <Card>
          <CardTitle>Cash at end of month</CardTitle>
          <Value>$7,684.00</Value>
        </Card>
        <Card>
          <CardTitle>Quick Ratio</CardTitle>
          <Value>1.02</Value>
        </Card>
        <Card>
          <CardTitle>Current Ratio</CardTitle>
          <Value>3.02</Value>
        </Card>
      </SummarySection>
      <ChartsSection>
        <ChartRow>
          <SmallCard>
            <CardTitle>Income & Expenses</CardTitle>
            <Bar data={incomeData} />
          </SmallCard>
          <SmallCard>
            <CardTitle>Net Profit Margin %</CardTitle>
            <Doughnut data={netProfitData} />
          </SmallCard>
        </ChartRow>
        <Card>
          <CardTitle>Monthly Financial Data</CardTitle>
          <Table>
            <thead>
              <tr>
                <TableHeader>Month</TableHeader>
                <TableHeader>Income ($)</TableHeader>
                <TableHeader>Expenses ($)</TableHeader>
                <TableHeader>Profit ($)</TableHeader>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <TableCell>{row.month}</TableCell>
                  <TableCell>{row.income}</TableCell>
                  <TableCell>{row.expenses}</TableCell>
                  <TableCell>{row.profit}</TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </ChartsSection>
    </DashboardContainer>
  );
};

export default FinanceDashboard;
