// AddUserModal.js
import React, { useState } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { createUsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { toast } from 'react-toastify';

const AddUserModal = ({ isOpen, toggle }) => {
  const [formValues, setFormValues] = useState({
    userid: '',
    position: '',
    work_location: '',
    department: '',
    section: '',
    name: '',
    employment_number: '',
    job_title: '',
    business_analyst_details: '',
    work_type: '',
    direct_manager: '',
    branch: '',
    hiring_date: '',
    end_of_probation: '',
    gender: '',
    birth_date: '',
    marital_status: '',
    nationality: '',
    national_id: '',
    email: '',
    mobile_number: '',
    phone_no_ext: '',
    img: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFormValues({
      ...formValues,
      img: file,
    });
  };

  const handleSubmit = () => {
    createUsersPersonalDetail(formValues)
      .then(response => {
        toast.success('User added successfully!');
        toggle();
      })
      .catch(error => {
        if (error.response) {
          console.error('Server responded with an error:', error.response.data);
          toast.error(`Failed to add user: ${error.response.data.message}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          toast.error('Failed to add user: No response received from server');
        } else {
          console.error('Error setting up request:', error.message);
          toast.error(`Failed to add user: ${error.message}`);
        }
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New User</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="userid">User ID</Label>
                <Input
                  type="text"
                  name="userid"
                  id="userid"
                  value={formValues.userid}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="mobile_number">Mobile Number</Label>
                <Input
                  type="text"
                  name="mobile_number"
                  id="mobile_number"
                  value={formValues.mobile_number}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="position">Position</Label>
                <Input
                  type="text"
                  name="position"
                  id="position"
                  value={formValues.position}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="department">Department</Label>
                <Input
                  type="text"
                  name="department"
                  id="department"
                  value={formValues.department}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="work_location">Work Location</Label>
                <Input
                  type="text"
                  name="work_location"
                  id="work_location"
                  value={formValues.work_location}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="section">Section</Label>
                <Input
                  type="text"
                  name="section"
                  id="section"
                  value={formValues.section}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="employment_number">Employment Number</Label>
                <Input
                  type="text"
                  name="employment_number"
                  id="employment_number"
                  value={formValues.employment_number}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="job_title">Job Title</Label>
                <Input
                  type="text"
                  name="job_title"
                  id="job_title"
                  value={formValues.job_title}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="business_analyst_details">Business Analyst Details</Label>
                <Input
                  type="text"
                  name="business_analyst_details"
                  id="business_analyst_details"
                  value={formValues.business_analyst_details}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="work_type">Work Type</Label>
                <Input
                  type="text"
                  name="work_type"
                  id="work_type"
                  value={formValues.work_type}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="direct_manager">Direct Manager</Label>
                <Input
                  type="text"
                  name="direct_manager"
                  id="direct_manager"
                  value={formValues.direct_manager}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="branch">Branch</Label>
                <Input
                  type="text"
                  name="branch"
                  id="branch"
                  value={formValues.branch}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="hiring_date">Hiring Date</Label>
                <Input
                  type="date"
                  name="hiring_date"
                  id="hiring_date"
                  value={formValues.hiring_date}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="end_of_probation">End of Probation</Label>
                <Input
                  type="date"
                  name="end_of_probation"
                  id="end_of_probation"
                  value={formValues.end_of_probation}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="text"
                  name="gender"
                  id="gender"
                  value={formValues.gender}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="birth_date">Birth Date</Label>
                <Input
                  type="date"
                  name="birth_date"
                  id="birth_date"
                  value={formValues.birth_date}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="marital_status">Marital Status</Label>
                <Input
                  type="text"
                  name="marital_status"
                  id="marital_status"
                  value={formValues.marital_status}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="nationality">Nationality</Label>
                <Input
                  type="text"
                  name="nationality"
                  id="nationality"
                  value={formValues.nationality}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="national_id">National ID</Label>
                <Input
                  type="text"
                  name="national_id"
                  id="national_id"
                  value={formValues.national_id}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone_no_ext">Phone Extension</Label>
                <Input
                  type="text"
                  name="phone_no_ext"
                  id="phone_no_ext"
                  value={formValues.phone_no_ext}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
         {/* <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="img">Profile Image</Label>
                <Input
                  type="file"
                  name="img"
                  id="img"
                  onChange={handleFileUpload}
                />
              </FormGroup>
            </Col>
          </Row>  */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>Add User</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserModal;
