import React from 'react'

function FinanaceEntry() {
  return (
    <div>
      <h1>
      Finance report entry
      </h1>
    </div>
  )
}

export default FinanaceEntry
