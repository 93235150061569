import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const ProfessionalInfoModal = ({ isOpen, toggle, formValues, handleFormChange, handleSaveUserInfo }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Professional Info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="job_title">Job Title</Label>
            <Input
              type="text"
              name="job_title"
              id="job_title"
              value={formValues.job_title}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="branch">Branch</Label>
            <Input
              type="text"
              name="branch"
              id="branch"
              value={formValues.branch}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="work_type">Work Type</Label>
            <Input
              type="text"
              name="work_type"
              id="work_type"
              value={formValues.work_type}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="work_location">Work Location</Label>
            <Input
              type="text"
              name="work_location"
              id="work_location"
              value={formValues.work_location}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="direct_manager">Direct Manager</Label>
            <Input
              type="text"
              name="direct_manager"
              id="direct_manager"
              value={formValues.direct_manager}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="department">Department</Label>
            <Input
              type="text"
              name="department"
              id="department"
              value={formValues.department}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="hiring_date">Hiring Date</Label>
            <Input
              type="date"
              name="hiring_date"
              id="hiring_date"
              value={formValues.hiring_date}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="end_of_probation">End of Probation</Label>
            <Input
              type="date"
              name="end_of_probation"
              id="end_of_probation"
              value={formValues.end_of_probation}
              onChange={handleFormChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveUserInfo}>
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfessionalInfoModal;
