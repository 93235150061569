import React, { useState, useEffect, Fragment } from 'react';
import { Col, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ArrowLeft } from "react-feather";
import { UsersPersonalDetail, updateUsersPersonalDetail, deleteUsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { UsersBankAccounts, createUsersBankAccounts, updateUsersBankAccounts, deleteUsersBankAccounts } from 'Services/usersBankAccounts.service';
import { UsersFamilyDetail, createUsersFamilyDetail, updateUsersFamilyDetail, deleteUsersFamilyDetail } from 'Services/usersFamilyDetail.service';
import { UsersInsurances, createUsersInsurances, updateUsersInsurances, deleteUsersInsurances } from 'Services/usersInsurances.service';    
import { UsersTicketAllowances, createUsersTicketAllowances, updateUsersTicketAllowances, deleteUsersTicketAllowances } from 'Services/usersTicketAllowances.service';  
import { UsersContracts, createUsersContracts, updateUsersContracts, deleteUsersContracts } from 'Services/usersContracts.service';  
import { UsersWarning, createUsersWarning, updateUsersWarning, deleteUsersWarning } from 'Services/usersWarning.service';   
import { toast } from 'react-toastify';
import AddUserModal from './modal/personal-tab/AddNewUser';
import UserInfoModal from './modal/personal-tab/UserInfoModal';
import PersonalInfoModal from './modal/personal-tab/PersonalInfoModal';
import ContactInfoModal from './modal/personal-tab/ContactInfoModal';
import ProfessionalInfoModal from './modal/personal-tab/ProfessionalInfoModal';
import DeleteConfirmationModal from './modal/personal-tab/DeleteConfirmationModal';
import './AdminEmployeeManagement.css';

const AdminEmployeeManagement = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(localStorage.getItem('selectedDepartment') || null);
  const [selectedPerson, setSelectedPerson] = useState(JSON.parse(localStorage.getItem('selectedPerson')) || null);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Profile');
  const [personData, setPersonData] = useState({ SALES: [], OTHER: [] });
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [personalInfoModal, setPersonalInfoModal] = useState(false);
  const [contactInfoModal, setContactInfoModal] = useState(false);
  const [professionalInfoModal, setProfessionalInfoModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [personToDelete, setPersonToDelete] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [bankAccountFormValues, setBankAccountFormValues] = useState({
    bank_name: '',
    branch: '',
    account_number: '',
    iban: '',
    transfer_percent: '100.00'
  });
  const [isEditMode, setIsEditMode] = useState(false);

  // Family info states
  const [familyDetails, setFamilyDetails] = useState([]);
  const [familyDetailModal, setFamilyDetailModal] = useState(false);
  const [familyFormValues, setFamilyFormValues] = useState({
    relation: '',
    personName: '',
    phonenumber: ''
  });
  const [isFamilyEditMode, setIsFamilyEditMode] = useState(false);

  // Insurance info states
  const [insurances, setInsurances] = useState([]);
  const [insuranceModal, setInsuranceModal] = useState(false);
  const [insuranceFormValues, setInsuranceFormValues] = useState({
    employeeName: '',
    insurerName: '',
    startDate: '',
    endDate: '',
    deduction: ''
  });
  const [isInsuranceEditMode, setIsInsuranceEditMode] = useState(false);

  // Ticket Allowance info states
  const [ticketAllowances, setTicketAllowances] = useState([]);
  const [ticketAllowanceModal, setTicketAllowanceModal] = useState(false);
  const [ticketAllowanceFormValues, setTicketAllowanceFormValues] = useState({
    employeeName: '',
    transactionType: '',
    date: '',
    active: true
  });
  const [isTicketAllowanceEditMode, setIsTicketAllowanceEditMode] = useState(false);

  // Contract info states
  const [contracts, setContracts] = useState([]);
  const [contractModal, setContractModal] = useState(false);
  const [contractFormValues, setContractFormValues] = useState({
    contractName: '',
    startDate: '',
    endDate: '',
    contractType: ''
  });
  const [isContractEditMode, setIsContractEditMode] = useState(false);

    // Warning info states
    const [warnings, setWarnings] = useState([]);
    const [warningModal, setWarningModal] = useState(false);
    const [warningFormValues, setWarningFormValues] = useState({
      reason: '',
      managementfile: '',
      submitionfile: '',
      Date: ''
    });
    const [isWarningEditMode, setIsWarningEditMode] = useState(false);

  const [formValues, setFormValues] = useState({
    name: '',
    employment_number: '',
    userid: '',
    photo: '',
    gender: '',
    birth_date: '',
    marital_status: '',
    nationality: '',
    national_id: '',
    email: '',
    mobile_number: '',
    phone_no_ext: '',
    job_title: '',
    branch: '',
    work_type: '',
    work_location: '',
    direct_manager: '',
    department: '',
    hiring_date: '',
    end_of_probation: ''
  });

  useEffect(() => {
    UsersPersonalDetail()
      .then(res => {
        const salesData = res.filter(person => person.department === "Sales");
        const otherData = res.filter(person => person.department !== "Sales");
        setPersonData({ SALES: salesData, OTHER: otherData });
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedPerson]);
  
  const loadData = async () => {
    if (selectedPerson) {
      try {
        const accounts = await UsersBankAccounts();
        const filteredAccounts = accounts.filter(account => account.userid === selectedPerson.userid);
        setBankAccounts(filteredAccounts);
  
        const familyDetails = await UsersFamilyDetail();
        const filteredFamilyDetails = familyDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setFamilyDetails(filteredFamilyDetails);

        const insuranceDetails = await UsersInsurances();
        const filteredInsurances = insuranceDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setInsurances(filteredInsurances);

        const ticketAllowanceDetails = await UsersTicketAllowances();
        const filteredTicketAllowances = ticketAllowanceDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setTicketAllowances(filteredTicketAllowances);

        const contractDetails = await UsersContracts();
        const filteredContracts = contractDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setContracts(filteredContracts);

        const warningDetails = await UsersWarning();
        const filteredWarnings = warningDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setWarnings(filteredWarnings);

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response) {
          console.error('Server Response Error:', error.response.data);
        } else if (error.request) {
          console.error('No Response Error:', error.request);
        } else {
          console.error('General Error:', error.message);
        }
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('selectedDepartment', selectedDepartment);
  }, [selectedDepartment]);

  useEffect(() => {
    localStorage.setItem('selectedPerson', JSON.stringify(selectedPerson));
  }, [selectedPerson]);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleCardClick = (department) => {
    setSelectedDepartment(department);
  };

  const handlePersonClick = (person) => {
    setSelectedPerson(person);
    setFormValues({
      name: person.name,
      employment_number: person.employment_number,
      userid: person.userid,
      photo: person.photo,
      gender: person.gender,
      birth_date: person.birth_date,
      marital_status: person.marital_status,
      nationality: person.nationality,
      national_id: person.national_id,
      email: person.email,
      mobile_number: person.mobile_number,
      phone_no_ext: person.phone_no_ext,
      job_title: person.job_title,
      branch: person.branch,
      work_type: person.work_type,
      work_location: person.work_location,
      direct_manager: person.direct_manager,
      department: person.department,
      hiring_date: person.hiring_date,
      end_of_probation: person.end_of_probation
    });
  };

  const handleBackClick = () => {
    if (selectedPerson) {
      setSelectedPerson(null);
      setActiveTab('Profile');
    } else {
      setSelectedDepartment(null);
    }
  };

  const toggleUserInfoModal = () => setUserInfoModal(!userInfoModal);
  const togglePersonalInfoModal = () => setPersonalInfoModal(!personalInfoModal);
  const toggleContactInfoModal = () => setContactInfoModal(!contactInfoModal);
  const toggleProfessionalInfoModal = () => setProfessionalInfoModal(!professionalInfoModal);
  const toggleAddUserModal = () => setAddUserModal(!addUserModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleBankAccountModal = () => setBankAccountModal(!bankAccountModal);
  const toggleInsuranceModal = () => setInsuranceModal(!insuranceModal);
  const toggleTicketAllowanceModal = () => setTicketAllowanceModal(!ticketAllowanceModal);
  const toggleContractModal = () => setContractModal(!contractModal);
  const toggleWarningModal = () => setWarningModal(!warningModal);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleBankAccountFormChange = (e) => {
    const { name, value } = e.target;
    setBankAccountFormValues({ ...bankAccountFormValues, [name]: value });
  };

  const handleInsuranceFormChange = (e) => {
    const { name, value } = e.target;
    setInsuranceFormValues({ ...insuranceFormValues, [name]: value });
  };

  const handleTicketAllowanceFormChange = (e) => {
    const { name, value } = e.target;
    setTicketAllowanceFormValues({ ...ticketAllowanceFormValues, [name]: value });
  };

  const handleContractFormChange = (e) => {
    const { name, value } = e.target;
    setContractFormValues({ ...contractFormValues, [name]: value });
  };

  const handleWarningFormChange = (e) => {
    const { name, value } = e.target;
    setWarningFormValues({ ...warningFormValues, [name]: value });
  };


  const handleSaveUserInfo = () => {
    if (!selectedPerson) return;
  
    const formData = new FormData();
    formData.append('userid', selectedPerson.id);
    formData.append('name', formValues.name);
    formData.append('photo', formValues.photo); // Add the photo file to the FormData
  
    updateUsersPersonalDetail(selectedPerson.id, formData) // Adjust your API call to accept FormData
      .then(response => {
        setSelectedPerson({ ...selectedPerson, ...formValues });
        setPersonData(prevData => {
          const updatedData = { ...prevData };
          if (selectedPerson.department === "Sales") {
            updatedData.SALES = updatedData.SALES.map(person =>
              person.employment_number === formValues.employment_number ? { ...formValues } : person
            );
          } else {
            updatedData.OTHER = updatedData.OTHER.map(person =>
              person.employment_number === formValues.employment_number ? { ...formValues } : person
            );
          }
          return updatedData;
        });
  
        if (userInfoModal) toggleUserInfoModal();
        if (personalInfoModal) togglePersonalInfoModal();
        if (contactInfoModal) toggleContactInfoModal();
        if (professionalInfoModal) toggleProfessionalInfoModal();
  
        toast.success("User information updated successfully!");
      })
      .catch(error => {
        console.error("Error updating user information:", error);
        toast.error("Failed to update user information. Please try again.");
      });
  };
  

  const handleDeleteClick = (person) => {
    setPersonToDelete(person);
    toggleDeleteModal();
  };

  const confirmDelete = () => {
    if (!personToDelete) return;

    deleteUsersPersonalDetail(personToDelete.id)
      .then(() => {
        toast.success('User deleted successfully!');
        toggleDeleteModal();
        setPersonData(prevData => {
          const updatedData = { ...prevData };
          if (personToDelete.department === "Sales") {
            updatedData.SALES = updatedData.SALES.filter(person => person.id !== personToDelete.id);
          } else {
            updatedData.OTHER = updatedData.OTHER.filter(person => person.id !== personToDelete.id);
          }
          return updatedData;
        });
        setSelectedPerson(null);
      })
      .catch(error => {
        toast.error('Failed to delete user. Please try again.');
        console.error('Error deleting user:', error);
      });
  };

  const handleAddBankAccount = () => {
    setIsEditMode(false);
    setBankAccountFormValues({
      bank_name: '',
      branch: '',
      account_number: '',
      iban: '',
      transfer_percent: '100.00'
    });
    toggleBankAccountModal();
  };

  const handleEditBankAccount = (account) => {
    setIsEditMode(true);
    setBankAccountFormValues(account);
    toggleBankAccountModal();
  };

  const handleDeleteBankAccount = (accountId) => {
    deleteUsersBankAccounts(accountId)
      .then(() => {
        toast.success('Bank account deleted successfully!');
        setBankAccounts(prevAccounts => prevAccounts.filter(account => account.id !== accountId));
      })
      .catch(error => {
        toast.error('Failed to delete bank account. Please try again.');
        console.error('Error deleting bank account:', error);
      });
  };

  const handleSaveBankAccount = () => {
    const payload = {
      ...bankAccountFormValues,
      userid: selectedPerson.userid
    };
  
    if (isEditMode) {
      updateUsersBankAccounts(bankAccountFormValues.id, payload)
        .then((response) => {
          if (response.length !== 0) {
            toast.success('Bank account updated successfully!');
            loadData();
            setBankAccounts(prevAccounts => prevAccounts.map(account =>
             account.id === bankAccountFormValues.id ? bankAccountFormValues : account
            ));
            toggleBankAccountModal();
          } else {
            toast.error('Failed to update bank account.');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Failed to update bank account. Please try again.');
        });
    } else {
      createUsersBankAccounts(payload)
        .then((response) => {
          if (response.length !== 0) {
            toast.success('Bank account added successfully!');
            loadData();
            toggleBankAccountModal();
          } else {
            toast.error('Failed to add bank account.');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Failed to add bank account. Please try again.');
        });
    }
  };

  // Family Info Handlers
  const handleAddFamilyDetail = () => {
    setIsFamilyEditMode(false);
    setFamilyFormValues({
      relation: '',
      personName: '',
      phonenumber: ''
    });
    setFamilyDetailModal(true);
  };

  const handleEditFamilyDetail = (detail) => {
    setIsFamilyEditMode(true);
    setFamilyFormValues(detail);
    setFamilyDetailModal(true);
  };

  const handleDeleteFamilyDetail = (id) => {
    deleteUsersFamilyDetail(id)
      .then(() => {
        toast.success('Family detail deleted successfully!');
        setFamilyDetails(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete family detail. Please try again.');
        console.error('Error deleting family detail:', error);
      });
  };

  const handleSaveFamilyDetail = () => {
    const payload = {
      ...familyFormValues,
      userid: selectedPerson.userid
    };

    if (isFamilyEditMode) {
      updateUsersFamilyDetail(familyFormValues.id, payload)
        .then(() => {
          toast.success('Family detail updated successfully!');
          loadData();
          setFamilyDetailModal(false);
        })
        .catch(error => {
          console.error('Error updating family detail:', error);
          toast.error('Failed to update family detail. Please try again.');
        });
    } else {
      createUsersFamilyDetail(payload)
        .then(() => {
          toast.success('Family detail added successfully!');
          loadData();
          setFamilyDetailModal(false);
        })
        .catch(error => {
          console.error('Error adding family detail:', error);
          toast.error('Failed to add family detail. Please try again.');
        });
    }
  };

  // Insurance Info Handlers
  const handleAddInsuranceDetail = () => {
    setIsInsuranceEditMode(false);
    setInsuranceFormValues({
      employeeName: '',
      insurerName: '',
      startDate: '',
      endDate: '',
      deduction: ''
    });
    toggleInsuranceModal();
  };

  const handleEditInsuranceDetail = (detail) => {
    setIsInsuranceEditMode(true);
    setInsuranceFormValues(detail);
    toggleInsuranceModal();
  };

  const handleDeleteInsuranceDetail = (id) => {
    deleteUsersInsurances(id)
      .then(() => {
        toast.success('Insurance detail deleted successfully!');
        setInsurances(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete insurance detail. Please try again.');
        console.error('Error deleting insurance detail:', error);
      });
  };

  const handleSaveInsuranceDetail = () => {
    const payload = {
      ...insuranceFormValues,
      userid: selectedPerson.userid
    };

    if (isInsuranceEditMode) {
      updateUsersInsurances(insuranceFormValues.id, payload)
        .then(() => {
          toast.success('Insurance detail updated successfully!');
          loadData();
          toggleInsuranceModal(false);
        })
        .catch(error => {
          console.error('Error updating insurance detail:', error);
          toast.error('Failed to update insurance detail. Please try again.');
        });
    } else {
      createUsersInsurances(payload)
        .then(() => {
          toast.success('Insurance detail added successfully!');
          loadData();
          toggleInsuranceModal(false);
        })
        .catch(error => {
          console.error('Error adding insurance detail:', error);
          toast.error('Failed to add insurance detail. Please try again.');
        });
    }
  };

  // Ticket Allowance Info Handlers
  const handleAddTicketAllowanceDetail = () => {
    setIsTicketAllowanceEditMode(false);
    setTicketAllowanceFormValues({
      employeeName: '',
      transactionType: '',
      date: '',
      active: true
    });
    toggleTicketAllowanceModal();
  };

  const handleEditTicketAllowanceDetail = (detail) => {
    setIsTicketAllowanceEditMode(true);
    setTicketAllowanceFormValues(detail);
    toggleTicketAllowanceModal();
  };

  const handleDeleteTicketAllowanceDetail = (id) => {
    deleteUsersTicketAllowances(id)
      .then(() => {
        toast.success('Ticket Allowance detail deleted successfully!');
        setTicketAllowances(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete Ticket Allowance detail. Please try again.');
        console.error('Error deleting Ticket Allowance detail:', error);
      });
  };

  const handleSaveTicketAllowanceDetail = () => {
    const payload = {
      ...ticketAllowanceFormValues,
      userid: selectedPerson.userid
    };

    if (isTicketAllowanceEditMode) {
      updateUsersTicketAllowances(ticketAllowanceFormValues.id, payload)
        .then(() => {
          toast.success('Ticket Allowance detail updated successfully!');
          loadData();
          toggleTicketAllowanceModal(false);
        })
        .catch(error => {
          console.error('Error updating Ticket Allowance detail:', error);
          toast.error('Failed to update Ticket Allowance detail. Please try again.');
        });
    } else {
      createUsersTicketAllowances(payload)
        .then(() => {
          toast.success('Ticket Allowance detail added successfully!');
          loadData();
          toggleTicketAllowanceModal(false);
        })
        .catch(error => {
          console.error('Error adding Ticket Allowance detail:', error);
          toast.error('Failed to add Ticket Allowance detail. Please try again.');
        });
    }
  };

  // Contract Info Handlers
  const handleAddContractDetail = () => {
    setIsContractEditMode(false);
    setContractFormValues({
      contractName: '',
      startDate: '',
      endDate: '',
      contractType: ''
    });
    toggleContractModal();
  };

  const handleEditContractDetail = (detail) => {
    setIsContractEditMode(true);
    setContractFormValues(detail);
    toggleContractModal();
  };

  const handleDeleteContractDetail = (id) => {
    deleteUsersContracts(id)
      .then(() => {
        toast.success('Contract detail deleted successfully!');
        setContracts(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete contract detail. Please try again.');
        console.error('Error deleting contract detail:', error);
      });
  };

  const handleSaveContractDetail = () => {
    const payload = {
      ...contractFormValues,
      userid: selectedPerson.userid
    };

    if (isContractEditMode) {
      updateUsersContracts(contractFormValues.id, payload)
        .then(() => {
          toast.success('Contract detail updated successfully!');
          loadData();
          toggleContractModal(false);
        })
        .catch(error => {
          console.error('Error updating contract detail:', error);
          toast.error('Failed to update contract detail. Please try again.');
        });
    } else {
      createUsersContracts(payload)
        .then(() => {
          toast.success('Contract detail added successfully!');
          loadData();
          toggleContractModal(false);
        })
        .catch(error => {
          console.error('Error adding contract detail:', error);
          toast.error('Failed to add contract detail. Please try again.');
        });
    }
  };



   // Warning Info Handlers
   const handleAddWarningDetail = () => {
    setIsWarningEditMode(false);
    setWarningFormValues({
      reason: '',
      managementfile: '',
      submitionfile: '',
      Date: ''
    });
    toggleWarningModal();
  };

  const handleEditWarningDetail = (detail) => {
    setIsWarningEditMode(true);
    setWarningFormValues(detail);
    toggleWarningModal();
  };

  const handleDeleteWarningDetail = (id) => {
    deleteUsersWarning(id)
      .then(() => {
        toast.success('Warning detail deleted successfully!');
        setWarnings(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete warning detail. Please try again.');
        console.error('Error deleting warning detail:', error);
      });
  };

  const handleSaveWarningDetail = () => {
    const payload = {
        ...warningFormValues,
        userid: selectedPerson.userid, // Ensure userid is set here
    };

    if (isWarningEditMode) {
        updateUsersWarning(warningFormValues.id, payload)
            .then(() => {
                toast.success('Warning detail updated successfully!');
                loadData();
                toggleWarningModal(false);
            })
            .catch(error => {
                console.error('Error updating warning detail:', error);
                toast.error('Failed to update warning detail. Please try again.');
            });
    } else {
        createUsersWarning(payload)
            .then(() => {
                toast.success('Warning detail added successfully!');
                loadData();
                toggleWarningModal(false);
            })
            .catch(error => {
                console.error('Error adding warning detail:', error);
                toast.error('Failed to add warning detail. Please try again.');
            });
    }
};


  const calculatePeriodOfEmployment = (hiringDate) => {
    const startDate = new Date(hiringDate);
    const currentDate = new Date();

    const totalMonths = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + currentDate.getMonth() - startDate.getMonth();
    const totalDays = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const weeks = Math.floor((totalDays % 365 % 30) / 7);
    const days = totalDays % 365 % 30 % 7;

    return `${years} years, ${months} months, ${weeks} weeks, ${days} days`;
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }

    return age;
  };

  const renderPersons = (department) => {
    const persons = department === 'SALES' ? personData.SALES : personData.OTHER;

    return (
      <div className="parent-card">
        <div className="header-container">
          <ArrowLeft className="back-icon" onClick={handleBackClick} />
          <h2 className="department-title">{department === 'SALES' ? 'SALES' : 'OTHER'}</h2>
          <Button color="primary" className="add-button" onClick={toggleAddUserModal}>+ Add</Button>
        </div>
        <div className="persons-container">
          {persons.map((person, index) => (
            <div key={index} className="person-card" onClick={() => handlePersonClick(person)}>
              <div className="company-name">GoDo</div>
              <img src={person.photo} alt={`${person.name}'s photo`} className="person-photo" />
              <div className="person-details">
                <div className="person-name">{person.name}</div>
                <div className="person-designation">{person.job_title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPersonDetails = () => {
    const tabs = [
      'Profile',
      'Bank Accounts',
      'Family Info',
      'Documents',
      'Insurance',
      'Air Ticket Allowances',
      'Timeoff Setup',
      'Assets',
      'Contracts',
      'Off-Boarding',
      'Commission',
      'Warning'
    ];

    const renderTabContent = () => {
      switch (activeTab) {
        case 'Profile':
          return (
            <div className="tab-contentt">
              <div className="section user-info-card">
                <div className="section-header">
                  <h3 className="section-title">User Info</h3>
                  <Button color="primary" onClick={toggleUserInfoModal}>Edit</Button>
                </div>
                <div className="section-content">
                  <img src={selectedPerson.photo} alt={`${selectedPerson.name}'s photo`} className="person-photo-large" />
                  <div className="user-info">
                    <p><strong>Name: </strong>{selectedPerson.name}</p>
                    <p><strong>Employment Number: </strong>{selectedPerson.employment_number}</p>
                    <p><strong>User Support ID: </strong>{selectedPerson.userid}</p>
                  </div>
                </div>
              </div>

              <div className="section personal-info-card">
                <div className="section-header">
                  <h3 className="section-title">Personal Info</h3>
                  <Button color="primary" onClick={togglePersonalInfoModal}>Edit</Button>
                </div>
                <div className="section-content">
                  <p><strong>Gender: </strong>{selectedPerson.gender}</p>
                  <p><strong>Birth Date: </strong>{selectedPerson.birth_date}</p>
                  <p><strong>Age: </strong>{calculateAge(selectedPerson.birth_date)}</p>
                  <p><strong>Marital Status: </strong>{selectedPerson.marital_status}</p>
                  <p><strong>Nationality: </strong>{selectedPerson.nationality}</p>
                  <p><strong>National ID: </strong>{selectedPerson.national_id}</p>
                </div>
              </div>

              <div className="section contact-info-card">
                <div className="section-header">
                  <h3 className="section-title">Contact Info</h3>
                  <Button color="primary" onClick={toggleContactInfoModal}>Edit</Button>
                </div>
                <div className="section-content">
                  <p><strong>Email: </strong>{selectedPerson.email}</p>
                  <p><strong>Mobile Number: </strong>{selectedPerson.mobile_number}</p>
                  <p><strong>Phone No. And Ext: </strong>{selectedPerson.phone_no_ext}</p>
                </div>
              </div>

              <div className="section professional-info-card">
                <div className="section-header">
                  <h3 className="section-title">Professional Info</h3>
                  <Button color="primary" onClick={toggleProfessionalInfoModal}>Edit</Button>
                </div>
                <div className="section-content">
                  <p><strong>Job Title: </strong>{selectedPerson.job_title}</p>
                  <p><strong>Branch: </strong>{selectedPerson.branch}</p>
                  <p><strong>Work Type: </strong>{selectedPerson.work_type}</p>
                  <p><strong>Work Location: </strong>{selectedPerson.work_location}</p>
                  <p><strong>Direct Manager: </strong>{selectedPerson.direct_manager}</p>
                  <p><strong>Department: </strong>{selectedPerson.department}</p>
                  <p><strong>Hiring Date: </strong>{selectedPerson.hiring_date}</p>
                  <p><strong>End of Probation: </strong>{selectedPerson.end_of_probation}</p>
                  <p><strong>Period of Employment: </strong>{calculatePeriodOfEmployment(selectedPerson.hiring_date)}</p>
                </div>
              </div>

              <div className="section timeoff-balance-card">
                <h3 className="section-title">Timeoff Balances</h3>
                <div className="section-content timeoff-balances">
                  <div className="balance-item">
                    <h4>Sick Day off</h4>
                    <p><strong>Available Balance: </strong>{selectedPerson.sick_days_available}</p>
                    <p><strong>Up to end of year: </strong>{selectedPerson.sick_days_end_of_year}</p>
                  </div>
                  <div className="balance-item">
                    <h4>Annual Vacations</h4>
                    <p><strong>Available Balance: </strong>{selectedPerson.annual_vacations_available}</p>
                    <p><strong>Up to end of year: </strong>{selectedPerson.annual_vacations_end_of_year}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        case 'Bank Accounts':
          return (
            <div className="tab-contentt">
              <div className="section bank-accounts-card">
                <div className="section-header">
                  <h3 className="section-title">Bank Accounts</h3>
                  <Button  color="primary"  className="add-button" onClick={handleAddBankAccount}>+ Add</Button>
                </div>
                <table className="bank-accounts-table">
                  <thead>
                    <tr>
                      <th>Bank Name</th>
                      <th>Branch</th>
                      <th>Account Number</th>
                      <th>IBAN</th>
                      <th>Transfer Percent</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bankAccounts.map((account) => (
                      <tr key={account.id}> {/* Ensure each tr has a unique key */}
                        <td>{account.bank_name}</td>
                        <td>{account.branch}</td>
                        <td>{account.account_number}</td>
                        <td>{account.iban}</td>
                        <td>{account.transfer_percent}%</td>
                        <td>
                          <Button color="primary" className="edit-button" onClick={() => handleEditBankAccount(account)}>Edit</Button>
                        </td>
                        <td>
                          <Button color="danger" onClick={() => handleDeleteBankAccount(account.id)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing {bankAccounts.length} results</p>
              </div>
            </div>
          );
        case 'Family Info':
          return (
            <div className="tab-contentt">
              <div className="section family-info-card">
                <div className="section-header">
                  <h3 className="section-title">Employee Family</h3>
                  <Button color="primary" className="add-button" onClick={handleAddFamilyDetail}>+ Add</Button>
                </div>
                <table className="family-info-table">
                  <thead>
                    <tr>
                      <th>Relation</th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                  {familyDetails.map((detail) => (
                    <tr key={detail.id}> {/* Use a unique value like 'slno' as the key */}
                      <td>{detail.relation}</td>
                      <td>{detail.personName}</td>
                      <td>{detail.phonenumber}</td>
                      <td>
                        <Button color="primary" className="edit-button" onClick={() => handleEditFamilyDetail(detail)}>Edit</Button>
                      </td>
                      <td>
                        <Button color="danger" onClick={() => handleDeleteFamilyDetail(detail.id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>

                </table>
                <p>Showing {familyDetails.length} results</p>
              </div>
            </div>
          );
          case 'Documents':
            return (
              <div className="tab-contentt">
                <div className="section documents-card">
                  <div className="section-header">
                    <h3 className="section-title">Documents</h3>
                    <Button className="add-button">+ Add</Button>
                  </div>
                  <div className="document-category">
                    <h4>Emirates ID</h4>
                    <div className="document-items">
                      <div className="document-item">
                        <i className="feather icon-file-text"></i>
                        <div className="document-info">
                          <p>EMIRATES ID</p>
                          <p>Owner: Mohamed Haris</p>
                          <p>Created At: 2023-09-12</p>
                          <Button className="edit-button">Edit</Button>
                        </div>
                      </div>
                      <div className="document-item">
                        <i className="feather icon-file-text"></i>
                        <div className="document-info">
                          <p>EMIRATES ID</p>
                          <p>Owner: Mohamed Haris</p>
                          <p>Created At: 2022-11-11 <span className="expired-label">Expired</span></p>
                          <Button className="edit-button">Edit</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion">
                    <div className="accordion-item">Employee HR Letters</div>
                    <div className="accordion-item">Labor Card</div>
                    <div className="accordion-item">Passport</div>
                    <div className="accordion-item">Visa</div>
                  </div>
                </div>
              </div>
            );
            case 'Insurance':
              return (
                <div className="tab-contentt">
                  <div className="section insurance-card">
                    <div className="section-header">
                      <h3 className="section-title">Employee Insurances</h3>
                      <Button color="primary" className="add-button" onClick={handleAddInsuranceDetail}>+ Add</Button>
                    </div>
                    <table className="insurance-table">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Insurer Name</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Deduction</th>
                          <th>Active</th> {/* New Active Column */}
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {insurances.map((detail) => {
                          const isActive = new Date(detail.endDate) > new Date();
                          return (
                            <tr key={detail.id}>
                              <td>{detail.employeeName}</td>
                              <td>{detail.insurerName}</td>
                              <td>{detail.startDate}</td>
                              <td>{detail.endDate}</td>
                              <td>{detail.deduction}</td>
                              <td>{isActive ? 'Yes' : 'No'}</td> {/* Display Active Status */}
                              <td>
                                <Button color="primary" className="edit-button" onClick={() => handleEditInsuranceDetail(detail)}>Edit</Button>
                              </td>
                              <td>
                                <Button color="danger" onClick={() => handleDeleteInsuranceDetail(detail.id)}>Delete</Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <p>Showing {insurances.length} results</p>
                  </div>
                </div>
              );
            
           case 'Air Ticket Allowances':
          return (
            <div className="tab-contentt">
              <div className="section air-ticket-card">
                <div className="section-header">
                  <h3 className="section-title">Air Ticket Allowances</h3>
                  <Button color="primary" className="add-button" onClick={handleAddTicketAllowanceDetail}>+ Add</Button>
                </div>
                <table className="air-ticket-table">
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Transaction Type</th>
                      <th>Date</th>
                      <th>Active</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketAllowances.map((detail) => (
                        <tr key={detail.id}>
                            <td>{detail.employeeName}</td>
                            <td>{detail.transactionType}</td>
                            <td>{detail.date}</td>
                            <td>{detail.active ? 'Yes' : 'No'}</td>
                            <td>
                                <Button color="primary" className="edit-button" onClick={() => handleEditTicketAllowanceDetail(detail)}>Edit</Button>
                            </td>
                            <td>
                                <Button color="danger" onClick={() => handleDeleteTicketAllowanceDetail(detail.id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing {ticketAllowances.length} results</p>
              </div>
            </div>
          );
        case 'Timeoff Setup':
          return (
            <div className="tab-contentt">
              <div className="section timeoff-card">
                <div className="section-header">
                  <h3 className="section-title">Employee Time Offs</h3>
                  <Button className="add-button">+ Add Employee Time Off</Button>
                </div>
                <div className="toolbar-sear">
                  <Input type="text" className="search-input" placeholder="Search" />
                  <div className="toolbar-buttons">
                    <Button className="export-button">Export as</Button>
                    <Input type="select" className="results-per-page">
                      <option>Results per page 10</option>
                      {/* Add more options as needed */}
                    </Input>
                  </div>
                </div>
                <table className="timeoff-table">
                  <thead>
                    <tr>
                      <th>Time Off Type</th>
                      <th>Custom Setup</th>
                      <th>Open Balance</th>
                      <th>Carry Balance</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Annual Vacations</td>
                      <td>Yes</td>
                      <td>30.0 Days</td>
                      <td>22.5 Days</td>
                      <td><span className="status active">Active</span></td>
                      <td>
                        <Button className="action-button">Show Balance</Button>
                        <i className="feather icon-edit"></i>
                        <i className="feather icon-trash"></i>
                        <i className="feather icon-more-horizontal"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Sick Day off</td>
                      <td>No</td>
                      <td>15.0 Days</td>
                      <td>0.0 Days</td>
                      <td><span className="status active">Active</span></td>
                      <td>
                        <Button className="action-button">Show Balance</Button>
                        <i className="feather icon-edit"></i>
                        <i className="feather icon-trash"></i>
                        <i className="feather icon-more-horizontal"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>Showing 1 - 2 of 2 results</p>
              </div>
            </div>
          );
        case 'Assets':
          return (
            <div className="tab-contentt">
              <div className="section assets-card">
                <div className="section-header">
                  <h3 className="section-title">Assets List</h3>
                  <Button className="add-button">+ Add</Button>
                </div>
                <table className="assets-table">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Holding Date</th>
                      <th>Dropping Date</th>
                      <th>Attachment</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PC</td>
                      <td>2021-04-01</td>
                      <td>——</td>
                      <td>——</td>
                      <td><Button className="edit-button">Edit</Button></td>
                    </tr>
                  </tbody>
                </table>
                <p>Showing 1 - 1 of 1 results</p>
              </div>
            </div>
          );
        case 'Contracts':
          return (
            <div className="tab-contentt">
              <div className="section contracts-card">
                <div className="section-header">
                  <h3 className="section-title">Employee Contracts</h3>
                  <Button color="primary" className="add-button" onClick={handleAddContractDetail}>+ Add</Button>
                </div>
          
                <table className="contracts-table">
                  <thead>
                    <tr>
                      <th>Contract Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Contract Type</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contracts.map((detail) => (
                        <tr key={detail.id}>
                            <td>{detail.contractName}</td>
                            <td>{detail.startDate}</td>
                            <td>{detail.endDate}</td>
                            <td>{detail.contractType}</td>
                            <td>
                                <Button color="primary" className="edit-button" onClick={() => handleEditContractDetail(detail)}>Edit</Button>
                            </td>
                            <td>
                                <Button color="danger" onClick={() => handleDeleteContractDetail(detail.id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing {contracts.length} results</p>
              </div>
            </div>
          );
        case 'Off-Boarding':
          return (
            <div className="tab-contentt">
              <div className="section off-boarding-card">
                <div className="section-header">
                  <h3 className="section-title">Termination</h3>
                </div>
                <div className="off-boarding-steps">
                  <div className="step active">Off-Boarding (optional)</div>
                  <div className="step">Termination</div>
                </div>
                <div className="form-group">
                  <Label for="boarding-template">Boarding template</Label>
                  <Input type="select" id="boarding-template" className="boarding-template">
                    <option>Please select</option>
                    {/* Add more options as needed */}
                  </Input>
                  <Button className="skip-button">Skip Off-Boarding</Button>
                </div>
              </div>
            </div>
          );
          case 'Warning':
            return (
              <div className="tab-contentt">
                <div className="section warning-info-card">
                  <div className="section-header">
                    <h3 className="section-title">Warnings</h3>
                    <Button color="primary" className="add-button" onClick={handleAddWarningDetail}>+ Add</Button>
                  </div>
                  <table className="warning-info-table">
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th>Management File</th>
                        <th>Submission File</th>
                        <th>Date</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {warnings.map((detail) => (
                          <tr key={detail.id}>
                              <td>{detail.reason}</td>
                              <td>{detail.managementfile}</td>
                              <td>{detail.submitionfile}</td>
                              <td>{detail.Date}</td>
                              <td>
                                  <Button color="primary" className="edit-button" onClick={() => handleEditWarningDetail(detail)}>Edit</Button>
                              </td>
                              <td>
                                  <Button color="danger" onClick={() => handleDeleteWarningDetail(detail.id)}>Delete</Button>
                              </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                  <p>Showing {warnings.length} results</p>
                </div>
              </div>
            );
        default:
          return null;
      }
    };

    return (
      <div className="person-details-card">
        <div className="card-per">
          <div className="card-header-per">
            <ArrowLeft className="back-icon" onClick={handleBackClick} />
            <h2 className="person-name">{selectedPerson.name}</h2>
            <Button color="danger" onClick={() => handleDeleteClick(selectedPerson)}>Delete</Button>
          </div>
          <div className="tabs">
            {tabs.map((tab) => (
              <div
                key={tab} // Unique key for each tab
                className={`tab-item ${tab === activeTab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="card-body">
            {renderTabContent()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Col sm="12">
        {!selectedDepartment ? (
          <div className="department-card">
            <div className="department-title">DEPARTMENT</div>
            <div className="cards-container">
              <div className="cards" onClick={() => handleCardClick('SALES')}>SALES</div>
              <div className="cards" onClick={() => handleCardClick('OTHER')}>OTHER</div>
            </div>
          </div>
        ) : !selectedPerson ? (
          renderPersons(selectedDepartment)
        ) : (
          renderPersonDetails()
        )}
      </Col>

      {/* User Info Modal */}
      <UserInfoModal
        isOpen={userInfoModal}
        toggle={toggleUserInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/* Personal Info Modal */}
      <PersonalInfoModal
        isOpen={personalInfoModal}
        toggle={togglePersonalInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/* Contact Info Modal */}
      <ContactInfoModal
        isOpen={contactInfoModal}
        toggle={toggleContactInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/* Professional Info Modal */}
      <ProfessionalInfoModal
        isOpen={professionalInfoModal}
        toggle={toggleProfessionalInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/* Add User Modal */}
      <AddUserModal isOpen={addUserModal} toggle={toggleAddUserModal} />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        personToDelete={personToDelete}
        confirmDelete={confirmDelete}
      />

      {/* Bank Account Modal */}
      <Modal isOpen={bankAccountModal} toggle={toggleBankAccountModal}>
        <ModalHeader toggle={toggleBankAccountModal}>
          {isEditMode ? 'Edit Bank Account' : 'Add Bank Account'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="bank_name">Bank Name</Label>
            <Input
              type="text"
              name="bank_name"
              id="bank_name"
              value={bankAccountFormValues.bank_name}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="branch">Branch</Label>
            <Input
              type="text"
              name="branch"
              id="branch"
              value={bankAccountFormValues.branch}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="account_number">Account Number</Label>
            <Input
              type="text"
              name="account_number"
              id="account_number"
              value={bankAccountFormValues.account_number}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="iban">IBAN</Label>
            <Input
              type="text"
              name="iban"
              id="iban"
              value={bankAccountFormValues.iban}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="transfer_percent">Transfer Percent</Label>
            <Input
              type="number"
              name="transfer_percent"
              id="transfer_percent"
              value={bankAccountFormValues.transfer_percent}
              onChange={handleBankAccountFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveBankAccount}>
            {isEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleBankAccountModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Family Info Modal */}
      <Modal isOpen={familyDetailModal} toggle={() => setFamilyDetailModal(false)}>
        <ModalHeader toggle={() => setFamilyDetailModal(false)}>
          {isFamilyEditMode ? 'Edit Family Detail' : 'Add Family Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="relation">Relation</Label>
            <Input
              type="text"
              name="relation"
              id="relation"
              value={familyFormValues.relation}
              onChange={(e) => setFamilyFormValues({ ...familyFormValues, [e.target.name]: e.target.value })}
            />
          </div>
          <div>
            <Label for="personName">Name</Label>
            <Input
              type="text"
              name="personName"
              id="personName"
              value={familyFormValues.personName}
              onChange={(e) => setFamilyFormValues({ ...familyFormValues, [e.target.name]: e.target.value })}
            />
          </div>
          <div>
            <Label for="phonenumber">Phone Number</Label>
            <Input
              type="text"
              name="phonenumber"
              id="phonenumber"
              value={familyFormValues.phonenumber}
              onChange={(e) => setFamilyFormValues({ ...familyFormValues, [e.target.name]: e.target.value })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveFamilyDetail}>
            {isFamilyEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={() => setFamilyDetailModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Insurance Modal */}
      <Modal isOpen={insuranceModal} toggle={toggleInsuranceModal}>
        <ModalHeader toggle={toggleInsuranceModal}>
          {isInsuranceEditMode ? 'Edit Insurance Detail' : 'Add Insurance Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="employeeName">Employee Name</Label>
            <Input
              type="text"
              name="employeeName"
              id="employeeName"
              value={insuranceFormValues.employeeName}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="insurerName">Insurer Name</Label>
            <Input
              type="text"
              name="insurerName"
              id="insurerName"
              value={insuranceFormValues.insurerName}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={insuranceFormValues.startDate}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={insuranceFormValues.endDate}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="deduction">Deduction</Label>
            <Input
              type="number"
              name="deduction"
              id="deduction"
              value={insuranceFormValues.deduction}
              onChange={handleInsuranceFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveInsuranceDetail}>
            {isInsuranceEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleInsuranceModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Ticket Allowance Modal */}
      <Modal isOpen={ticketAllowanceModal} toggle={toggleTicketAllowanceModal}>
        <ModalHeader toggle={toggleTicketAllowanceModal}>
            {isTicketAllowanceEditMode ? 'Edit Air Ticket Allowance' : 'Add Air Ticket Allowance'}
        </ModalHeader>
        <ModalBody>
            <div>
                <Label for="employeeName">Employee Name</Label>
                <Input
                    type="text"
                    name="employeeName"
                    id="employeeName"
                    value={ticketAllowanceFormValues.employeeName}
                    onChange={handleTicketAllowanceFormChange}
                />
            </div>
            <div>
                <Label for="transactionType">Transaction Type</Label>
                <Input
                    type="text"
                    name="transactionType"
                    id="transactionType"
                    value={ticketAllowanceFormValues.transactionType}
                    onChange={handleTicketAllowanceFormChange}
                />
            </div>
            <div>
                <Label for="date">Date</Label>
                <Input
                    type="date"
                    name="date"
                    id="date"
                    value={ticketAllowanceFormValues.date}
                    onChange={handleTicketAllowanceFormChange}
                />
            </div>
            <div>
                <Label for="active">Active</Label>
                <Input
                    type="checkbox"
                    name="active"
                    id="active"
                    checked={ticketAllowanceFormValues.active}
                    onChange={(e) => setTicketAllowanceFormValues({ ...ticketAllowanceFormValues, [e.target.name]: e.target.checked })}
                />
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleSaveTicketAllowanceDetail}>
                {isTicketAllowanceEditMode ? 'Update' : 'Add'}
            </Button>{' '}
            <Button color="secondary" onClick={() => setTicketAllowanceModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Contract Modal */}
      <Modal isOpen={contractModal} toggle={toggleContractModal}>
        <ModalHeader toggle={toggleContractModal}>
          {isContractEditMode ? 'Edit Contract Detail' : 'Add Contract Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="contractName">Contract Name</Label>
            <Input
              type="text"
              name="contractName"
              id="contractName"
              value={contractFormValues.contractName}
              onChange={handleContractFormChange}
            />
          </div>
          <div>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={contractFormValues.startDate}
              onChange={handleContractFormChange}
            />
          </div>
          <div>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={contractFormValues.endDate}
              onChange={handleContractFormChange}
            />
          </div>
          <div>
            <Label for="contractType">Contract Type</Label>
            <Input
              type="text"
              name="contractType"
              id="contractType"
              value={contractFormValues.contractType}
              onChange={handleContractFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveContractDetail}>
            {isContractEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleContractModal}>Cancel</Button>
        </ModalFooter>
      </Modal>


      {/* Warning Modal */}
      <Modal isOpen={warningModal} toggle={toggleWarningModal}>
        <ModalHeader toggle={toggleWarningModal}>
          {isWarningEditMode ? 'Edit Warning Detail' : 'Add Warning Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="reason">Reason</Label>
            <Input
              type="text"
              name="reason"
              id="reason"
              value={warningFormValues.reason}
              onChange={handleWarningFormChange}
            />
          </div>
          <div>
            <Label for="managementfile">Management File</Label>
            <Input
              type="text"
              name="managementfile"
              id="managementfile"
              value={warningFormValues.managementfile}
              onChange={handleWarningFormChange}
            />
          </div>
          <div>
            <Label for="submitionfile">Submission File</Label>
            <Input
              type="text"
              name="submitionfile"
              id="submitionfile"
              value={warningFormValues.submitionfile}
              onChange={handleWarningFormChange}
            />
          </div>
          <div>
            <Label for="Date">Date</Label>
            <Input
              type="date"
              name="Date"
              id="Date"
              value={warningFormValues.Date}
              onChange={handleWarningFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveWarningDetail}>
            {isWarningEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleWarningModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      
    </Fragment>
  );
};

export default AdminEmployeeManagement;
