import api from './api';

export const UsersInsurances = () => {
    return api.get('/v1/crm/UsersInsurances');
};

export const createUsersInsurances = (data) => {
    return api.post('/v1/crm/UsersInsurances', data);
};

export const updateUsersInsurances = (id, data) => {
    return api.put(`/v1/crm/UsersInsurances/${id}`, data);
};

export const deleteUsersInsurances = (id) => {
    return api.delete(`/v1/crm/UsersInsurances/${id}`);
};
